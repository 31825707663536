import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import transicionEscalera from '../Transiciones/transicionEscalera'
import transicionBottomTop from '../Transiciones/transicionBottomTop'
import NavbarPortal from '../Navbars/NavbarPortal'
import FooterPortal from './FooterPortal'
import SideArticles from './SideArticles'
import Axios from "axios";
import { Adsense } from '@ctrl/react-adsense';
import { context } from '../Context/Context'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

const DetalleNota = () => {
    const { tituloNota } = useParams();

    const [nota, setNota] = useState([])
    const { convertTittleToUrl } = useContext(context)
    const [categoria, setCategoria] = useState("Categoria")
    const navegar = useNavigate()
    const now = new Date();

    const identificarNota = (noticiasArray) => {
        const filtro = noticiasArray.find(noticia => convertTittleToUrl(noticia.titulo) === tituloNota);
        const fechaHabilitacion = new Date(filtro.fecha_habilitacion);
        if (!filtro || fechaHabilitacion.getTime() > now.getTime()) {
            navegar('/portal-de-noticias')
        } else {
            setNota(filtro)
            switch(filtro.categoria){
                case "tecnofisp":
                    setCategoria("TecnoFISP")
                    break;
                case "datafisp":
                    setCategoria("DataFISP")
                    break;
                case "desde-adentro":
                    setCategoria("Desde Adentro")
                    break;
                case "vida-sana":
                    setCategoria("Vida Sana")
                    break;
                case "el-nudo":
                    setCategoria("El Nudo")
                    break;
                case "real-food":
                    setCategoria("Real Food")
                    break;
                case "en-foco":
                    setCategoria("En Foco")
                    break;
                case "eso-que-no-sabias":
                    setCategoria("Eso que no sabías")
                    break;
                default:
                    break;
            }
        }
    }

    const obtenerNotas = (retryCount = 0) => {
        Axios.get(`https://srv452353.hstgr.cloud/obtenernota/${tituloNota}`)
            .then((response) => {
                identificarNota(Array.from(response.data));
            })
            .catch((error) => {
                console.log("Error al traer la noticia", error);
            });
    }

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerNotas()
    },[])

    return (
        <>
            {nota && (
                <Helmet>
                    <title>{nota.titulo}</title>
                    <meta name="title" content={nota.titulo} />
                    <meta name="description" content={nota.descripcion} />
                    <meta property="og:image" content={nota.imagen} />
                    <meta property="og:title" content={nota.titulo} />
                    <meta property="og:description" content={nota.descripcion} />
                    <meta property="og:type" content="article" />
                </Helmet>
            )}
            <main className='multimedia-home-container oswald' id='main-element'>
                <NavbarPortal />
                <div className="container-xxl navbar-margin">
                    <div className="news-container d-flex">
                        {
                            nota.length!=0 ? (
                                <div className="article-content-column pe-md-3 pb-3 px-0 section-big mb-4">
                                    <div className="article-header px-md-5 px-2 box-shadow py-5">
                                        <div className="px-lg-5">
                                            <h3 className='fs-6'>{categoria}</h3>
                                            <h1 className='fs-3 mb-3 roboto'>{nota.titulo}</h1>
                                            <div className="article-content">
                                                <p className='p-0 m-0'>{nota.descripcion}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <iframe width="560" height="315" src={nota.link_video} frameBorder="0" title="frame" allowFullScreen></iframe>
                                    <div className="article-content px-md-3 px-lg-5 px-2 flex-column box-shadow d-flex justify-content-center pb-5">
                                        <div className='px-lg-5 px-md-3 px-0 py-md-5 py-1 fw-normal mt-4'>
                                            {parse(nota.cuerpo)}
                                        </div>
                                        <div className="ad-container">
                                            <Adsense
                                                className='w-100'
                                                client="ca-pub-8715176583976119"
                                                slot="3087387590"
                                                style={{ display: 'block' }}
                                                layout="in-article"
                                                format="fluid"
                                            />
                                        </div>
                                    </div>

                                    {
                                        nota.art_rel_1 !== null && nota.art_rel_2 !== null && nota.art_rel_3 !== null ? (
                                            <div className="related-articles-container border-top  mt-4 box-shadow">
                                                <div className="related-article-header pt-2 px-2 px-sm-4">
                                                    <h4 className='poppins d-flex gap-2 align-items-center fs-5'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z" strokeWidth="0" fill="currentColor" />
                                                        </svg>
                                                        <span className='py-2'>Seguir leyendo</span>
                                                    </h4>
                                                </div>
                                                <div className="related-article px-2 px-sm-4">
                                                    <Link className='w-100 h-100 py-3' to={`/portal-de-noticias/${convertTittleToUrl(nota.art_rel_1)}`}>
                                                    <h5 className='fs-5 m-0 roboto w-100 d-flex gap-2 align-items-center justify-content-between'>
                                                        {nota.art_rel_1}
                                                        <svg width="28" height="28" viewBox="0 2 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M5 12l14 0" />
                                                            <path d="M15 16l4 -4" />
                                                            <path d="M15 8l4 4" />
                                                        </svg>
                                                    </h5>
                                                    </Link>
                                                </div>
                                                <div className="related-article px-2 px-sm-4">
                                                    <Link className='w-100 h-100 py-3' to={`/portal-de-noticias/${convertTittleToUrl(nota.art_rel_2)}`}>
                                                    <h5 className='fs-5 m-0 roboto w-100 d-flex gap-2 align-items-center justify-content-between'>
                                                        {nota.art_rel_2}
                                                        <svg width="28" height="28" viewBox="0 2 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M5 12l14 0" />
                                                            <path d="M15 16l4 -4" />
                                                            <path d="M15 8l4 4" />
                                                        </svg>
                                                    </h5>
                                                    </Link>
                                                </div>
                                                <div className="related-article px-2 px-sm-4">
                                                    <Link className='w-100 h-100 py-3' to={`/portal-de-noticias/${convertTittleToUrl(nota.art_rel_3)}`}>
                                                    <h5 className='fs-5 m-0 roboto w-100 d-flex gap-2 align-items-center justify-content-between'>
                                                        {nota.art_rel_3}
                                                        <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M5 12l14 0" />
                                                            <path d="M15 16l4 -4" />
                                                            <path d="M15 8l4 4" />
                                                        </svg>
                                                    </h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : (<></>)
                                    }
                                </div>
                            ) : (
                                <div className="article-content-column section-big rounded-4 position-relative border border-primary d-flex justify-content-center mx-3" style={{'--bs-border-opacity': '.1'}}>
                                    <div className="skeleton-loader"></div>
                                </div>
                            )
                        }
                        <SideArticles />
                    </div>
                </div>
            </main>
            <FooterPortal />
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(DetalleNota) : transicionBottomTop(DetalleNota);



