import React, { useEffect, useState, useRef, useContext } from 'react'
import Axios from "axios";
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import { Helmet } from 'react-helmet';
import { context } from '../Context/Context'
import NavbarAdmin from '../Navbars/NavbarAdmin';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PrevisualizarNota = () => {

    const { convertTittleToUrl } = useContext(context)
    const [titulo, setTitulo] = useState("")
    const [noticias, setNoticias] = useState(null);
    const [noticiasProgramadas, setNoticiasProgramadas] = useState(null);
    const isMountedRef = useRef(false);

    const navigate = useNavigate();


    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerNotasProgramadas()
        obtenerNotas()
    },[])

    const obtenerNotas = () => {
        Axios.get("https://srv452353.hstgr.cloud/obtenernotas/")
        .then((response) => {
            console.log("Noticias recibidas")
            setNoticias(Array.from(response.data));
        })
        .catch((error) => {
            console.error(error);
        })
    }
    
    const obtenerNotasProgramadas = () => {
        Axios.get("https://srv452353.hstgr.cloud/obtenernotas-programadas/")
        .then((response) => {
            console.log("Noticias recibidas")
            setNoticiasProgramadas(Array.from(response.data));
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return (
        <main className='main-editor-container'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <NavbarAdmin menu={true} link={3} />
            <ToastContainer />
            <div className='container-xxl d-flex flex-column align-items-center my-5'>
                <div className="editor d-flex align-items-center flex-column gap-2 w-100">
                    <h3 className="negro poppins-bold pt-4">PREVISUALIZAR NOTA</h3>
                    <div className="col-12 pt-2 pb-5 d-flex flex-column px-4">
                        <label className='pb-3 poppins-semibold' htmlFor="article-select">Notas programadas:</label>
                        {noticiasProgramadas &&
                            noticiasProgramadas
                            .sort((a, b) => new Date(b.fecha_habilitacion) - new Date(a.fecha_habilitacion))
                            .map((item, index) => (
                            <button id="editor-prev-card" className="d-flex align-items-center justify-content-between" key={index} onClick={() => navigate(`/portal-de-noticias/editor/previsualizar-nota/${convertTittleToUrl(item.titulo)}`)}>
                                <div className="d-flex align-items-center">
                                    <img id="editor-prev-img" className="m-3" src={item.imagen} alt="img" />
                                    <p className="poppins-semibold m-0">{item.titulo}</p>
                                </div>
                                <p className="poppins-light m-0 pe-3">Fecha Habilitación: {new Date(item.fecha_habilitacion).toLocaleDateString('en-GB')} 21hs</p>
                            </button>
                        ))}
                        <label className='pt-5 pb-3 poppins-semibold' htmlFor="article-select">Seleccionar otra nota:</label>
                        <div className="d-flex align-items-center w-100 mb-3">
                            <select 
                                name="article"
                                id="article-select"
                                onChange={(e)=>{
                                    const selectedNoticia = noticias.find(noticia => noticia.titulo === e.target.value);
                                    setTitulo(selectedNoticia.titulo)
                                }}
                                className="form-select p-2"
                                aria-label="Default select example">
                                <option value="" selected disabled></option>
                                {noticias &&
                                    noticias.map((noticia,index)=>{
                                        return(
                                            <option key={index} value={noticia.titulo}>{noticia.titulo}</option>
                                        )
                                    })
                                }
                            </select>
                            <button id="editor-prev-button" className="ms-3 px-5 py-2" onClick={() => navigate(`/portal-de-noticias/editor/previsualizar-nota/${convertTittleToUrl(titulo)}`)}>Ir</button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    );
}

export default window.innerWidth >= 1000 ? transicionEscalera(PrevisualizarNota) : transicionBottomTop(PrevisualizarNota);
