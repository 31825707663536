import React from 'react';
import { Link } from 'react-router-dom'
import Logo from '../multimedia/imagenes/logo-iniciales-oscuro.png'

const NavbarAdmin = ({ menu, link }) => {
    return (
        <nav id="nav-admin" className="d-flex align-items-center w-100 box-shadow">
            {menu &&
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex align-items-center">
                        <div id="nav-admin-logo-container" className="d-flex align-items-center ms-5 my-3">
                            <Link  className='d-flex align-items-center' to="/">
                                <img id="nav-admin-logo" src={Logo} alt="logo fisp" />
                            </Link>
                        </div>
                        <h4 className="mb-0 mt-1 ms-4 fw-bold poppins">ADMIN</h4>
                    </div>
                    <div id="nav-admin-links" className="mt-1 mx-4 d-flex align-items-center">
                        {link != 1 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/agregar-nota">AGREGAR NOTA</Link>}
                        {link === 1 && <div className='text-center nav-admin-link poppins py-2 mx-3'>AGREGAR NOTA</div>}
                        {link != 2 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/editar-nota">EDITAR NOTA</Link>}
                        {link === 2 && <div className='text-center nav-admin-link poppins py-2 mx-3'>EDITAR NOTA</div>}
                        {link != 3 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/previsualizar-nota">PREVISUALIZAR NOTA</Link>}
                        {link === 3 && <div className='text-center nav-admin-link poppins py-2 mx-3'>PREVISUALIZAR NOTA</div>}
                        {link != 4 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/organizar-notas">EDITAR HOME</Link>}
                        {link === 4 && <div className='text-center nav-admin-link poppins py-2 mx-3'>EDITAR HOME</div>}
                        {link != 5 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/organizar-side">EDITAR APARTADO</Link>}
                        {link === 5 && <div className='text-center nav-admin-link poppins py-2 mx-3'>EDITAR APARTADO</div>}
                        {link != 6 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/editar-reels">EDITAR REELS</Link>}
                        {link === 6 && <div className='text-center nav-admin-link poppins py-2 mx-3'>EDITAR REELS</div>}
                        {link != 7 && <Link className='text-center nav-admin-link text-decoration-none poppins negro py-2 mx-3' to="/portal-de-noticias/editor/editar-stream">EDITAR STREAM</Link>}
                        {link === 7 && <div className='text-center nav-admin-link poppins py-2 mx-3'>EDITAR STREAM</div>}
                    </div>
                    <div id="nav-admin-extra"></div>
                </div>
            }
            {!menu && 
            <div className="d-flex align-items-center">
                <div id="nav-admin-logo-container" className="d-flex align-items-center ms-5 my-3">
                    <Link  className='d-flex align-items-center' to="/">
                        <img id="nav-admin-logo" src={Logo} alt="logo fisp" />
                    </Link>
                </div>
                <h4 className="mb-0 mt-1 ms-4 fw-bold poppins">ADMIN</h4>
            </div>
            }
        </nav>
    );
}

export default NavbarAdmin;
