import React, { useEffect, useRef, useState } from 'react'
import Axios from "axios";
import 'react-quill/dist/quill.snow.css';
import NavbarAdmin from '../Navbars/NavbarAdmin';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import { ToastContainer, toast } from 'react-toastify'
import editorSkeleton from '../multimedia/imagenes/portal/reels-layout.png'
import { Helmet } from 'react-helmet';

const EditarReels = () => {

    const [reels,setReels] = useState([])
    const [linkReel,setLinkReel] = useState("")
    const [posicionReel,setPosicionReel] = useState("")
    
    const actualizarReels = ()=>{
        if(linkReel!=""&&posicionReel!=""){
            Axios.post("https://srv452353.hstgr.cloud/editarreels",{
                link: linkReel,
                posicion: posicionReel
            }).then((result)=>{
                console.log(result)
                console.log("Se actualizó el reel con exito")
            }).catch((e)=>{
                console.log("Error")
                console.log(e)
            }).finally(()=>{
                toast.success("Se actualizó el reel de posicion ", posicionReel,"a ",linkReel, " con éxito.")
                setTimeout(()=>{
                    window.location.reload();
                },[1500])
            })
        } else{
            alert("Falta ingresar el link o la posición del reel")
        }
    }
    const obtenerReels = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerreels/")
        .then((response) => {
            console.log("Reels del home recibidas con éxito")
            setReels(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer los reels del home")
            console.error(error);
        })
    }

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerReels()
    },[])

    return (
        <main className='main-editor-container'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <NavbarAdmin menu={true} link={6}/>
            <ToastContainer />
            <div className='container-xxl my-5 d-flex flex-column align-items-center'>
                <div className="editor d-flex align-items-center flex-column gap-2 w-100">
                    <div className="col-12 pt-4 pb-5 d-flex flex-column px-4">
                        <div className="w-100 d-flex flex-column justify-content-center py-4 mb-3">
                            <p className='text-center poppins fs-2 border-bottom pb-2 uppercase'>Layout Reels</p>
                            <img className='w-100 rounded-4 box-shadow' src={editorSkeleton} alt="" />
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-4 mb-4">
                            <label htmlFor="link-reel">Link de Youtube</label>
                            <input className='p-2 col-8 rounded-3' id='link-reel' type="text" onChange={(e)=>{setLinkReel(e.target.value)}} />
                            <label htmlFor="orden-reel">Posición</label>
                            <select 
                                name="orden-reel"
                                id="orden-reel" 
                                className='p-2 col-1 rounded-3'
                                onChange={(e)=>{setPosicionReel(e.target.value)}}
                                >
                                <option value="" disabled selected></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                        <div className="border p-2">
                            <button className='px-5 w-100 py-3 border-0' onClick={()=>{
                                actualizarReels()
                            }}>
                                Actualizar
                            </button>
                        </div>
                        <div className="d-flex reels-section justify-content-between overflow-hidden mt-3">
                            {reels.map((reel,index)=>{
                                return(
                                    <iframe height={450} width={240} key={index} className='rounded-4 reel' src={reel.link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(EditarReels) : transicionBottomTop(EditarReels);
