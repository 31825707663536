import React, { useEffect, useState } from 'react'
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Modal = ({linkCurso, tituloCurso, tag, toggleModal, setToggleModal}) => {

    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [curso, setCurso] = useState(tituloCurso);
    const [url, setUrl] = useState(linkCurso);
    const [tagCurso, setTagCurso] = useState(tag)
    const [formMessage,setFormMessage] = useState("")
    const [isError, setIsError] = useState()
    const [buttonState, setButtonState] = useState(true)
    const [sent, setSent] = useState(false)

    const handleSubmitContact = async (event) => {
        event.preventDefault();
        setButtonState(false);
        crearContacto()
    };

    useEffect(() => {
        (async () => {
            if (!buttonState) {
                await new Promise(resolve => setTimeout(resolve, 3000));
                setButtonState(true);
            }
        })();
    }, [buttonState]);

    useEffect(() => {
        (async () => {
            if (sent) {
                await new Promise(resolve => setTimeout(resolve, 5000));
                setSent(false);
            }
        })();
    }, [sent]);

    const crearContacto = () => {
        if (!sent) {
            Axios.post('https://srv452353.hstgr.cloud/kommoCrearContacto', {
                nombre: name,
                telefono: phone,
                email: email,
                curso: curso,
            })
            .then((response) => {
                setSent(true);
                console.log("Contacto creado")
                crearLead(response.data._embedded.contacts[0].id)
            })
            .catch((error) => {
                resetForm()
                console.log("Error al crear contacto")
                setIsError(true)
                setFormMessage("Ha habido un error al crear el contacto. Si el error persiste, contactese directamente con nosotros.")
                setButtonState(true);
            })
            .finally(() => {
                console.log("Proceso finalizado")
            });
        }
    }

    const crearLead = (contactoId)=>{
        Axios.post('https://srv452353.hstgr.cloud/kommoCrearLead', {
            nombre: name,
            telefono: phone,
            email: email,
            curso: curso,
            url: url,
            contactoId: contactoId,
            tagCurso: tagCurso
        })
        .then((response) => {
            console.log("Lead creado")
            setIsError(false)
            setFormMessage("Sus datos se han registrado con éxito. En unos instantes le llegará un mensaje a su telefono con información sobre precios y formas de inscripcion para '" + curso + "'")
            setButtonState(true);
        })
        .catch((error) => {
            resetForm()
            console.log("Error al crear lead")
            setButtonState(true);
            setIsError(true)
            setFormMessage("Ha habido un error al registrar los datos, pruebe denuevo mas tarde. Si el error persiste, contactese directamente con nosotros.")
        })
        .finally(() => {
            console.log("Proceso finalizado")
        });
    }

    const resetForm = ()=>{
        setName('')
        setEmail('')
        setPhone('')
    }

    return (
        <div className={`${toggleModal ? 'modal-show' : ''} modal-custom`} >
            <ToastContainer />
            <div className="modal-form">
                <svg onClick={()=>{setToggleModal(false)}} width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                </svg>
                <div className="d-flex flex-column p-sm-4 py-3">
                    <form onSubmit={handleSubmitContact}>
                        <h2 className='poppins fs-5 d-flex flex-column'>
                            Quiero más información sobre: 
                            <span className="poppins-semibold">{tituloCurso}</span>
                        </h2>
                        <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                            <input
                                required
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Nombre y apellido'
                            />
                        </div>
                        <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                            <input
                                required
                                placeholder='Email'
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                            <PhoneInput
                                required
                                placeholder="Número telefónico"
                                value={phone}
                                defaultCountry='AR'
                                onChange={setPhone}/>
                        </div>
                        {buttonState && <button type="submit">Enviar</button>}
                        {!buttonState &&
                            <div className="d-flex justify-content-center align-items-center py-3 mt-1 fade-in-500">
                                <div className="modal-spinner"></div>
                            </div>
                        }
                    </form>
                    <div className='d-flex flex-column kommo-input-container border montserrat mt-3'>
                        {formMessage && (
                            <p className={`p-3 m-0 montserrat fade-in ${isError ? 'form-error' : 'form-success'}`} style={{fontSize: '14px',fontWeight: '500'}}>{formMessage}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal