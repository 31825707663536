import React from 'react'
import { Routes,Route,useLocation } from 'react-router-dom';
import Home from './Componentes/Home';
import Servicios from './Componentes/Servicios';
import ServicioRedirect from './Componentes/Servicios/ServicioRedirect';
import Educativo from './Componentes/Educativo';
import ServicioTecnologicoRedirect from './Componentes/Servicios/ServiciosTecnologicos/ServicioTecnologicoRedirect';
import ServicioMedicoRedirect from './Componentes/Servicios/ServiciosMedicos/ServicioMedicoRedirect';
import ProduccionMarketingRedirect from './Componentes/Servicios/ProduccionMarketing/ProduccionMarketingRedirect';
import Fundacion from './Componentes/Servicios/Fundacion';
import {AnimatePresence} from 'framer-motion'
import Staff from './Componentes/Servicios/Staff';
import ListaCursos from './Componentes/Educativo/ListaCursos';
import CursoRedirect from './Componentes/Educativo/Cursos/Redirects/CursoRedirect';
import PaginaError from './Componentes/PaginaError';
import ListaDiplomaturas from './Componentes/Educativo/ListaDiplomaturas';
import ListaPosgrados from './Componentes/Educativo/ListaPosgrados';
import PosgradoRedirect from './Componentes/Educativo/Cursos/Redirects/PosgradoRedirect';
import DiplomaturaRedirect from './Componentes/Educativo/Cursos/Redirects/DiplomaturaRedirect';
import FiltroCategoria from './Componentes/Educativo/Cursos/Redirects/FiltroCategoria';
import TerminosCondiciones from './Componentes/TerminosCondiciones';
import ContactoEducativo from './Componentes/Educativo/ContactoEducativo';
import ContactoServicios from './Componentes/Servicios/ContactoServicios';
import PreInscripcion from './Componentes/Educativo/Cursos/Redirects/PreInscripcion';
import PortalLogin from './Componentes/Portal/PortalLogin';
import LoginPortalRedirect from './Componentes/Portal/LoginPortalRedirect';
import LoginEducativoRedirect from './Componentes/Educativo/LoginEducativoRedirect';
import AgregarNota from './Componentes/Portal/AgregarNota';
import EditarNota from './Componentes/Portal/EditarNota';
import Editor from './Componentes/Portal/Editor';
import PortalV2 from './Componentes/PortalV2';
import DetalleNota from './Componentes/Portal/DetalleNota';
import PortalCategoriaV2 from './Componentes/Portal/PortalCategoriaV2';
import PortalBusquedaV2 from './Componentes/Portal/PortalBusquedaV2';
import OrganizarNotas from './Componentes/Portal/OrganizarNotas';
import EditarReels from './Componentes/Portal/EditarReels';
import OrganizarSide from './Componentes/Portal/OrganizarSide';
import EducativoLogin from './Componentes/Educativo/EducativoLogin';
import ListadoFormularios from './Componentes/Educativo/ListadoFormularios';
import EditarStream from './Componentes/Portal/EditarStream';
import Cyberweek from './Componentes/Educativo/Cyberweek';
import ServiciosTecnologicosV3 from './Componentes/Servicios/ServiciosTecnologicos/ServiciosTecnologicosV3';
import PrevisualizarNota from './Componentes/Portal/PrevisualizarNota';
import DetalleNotaPrev from './Componentes/Portal/DetalleNotaPrev';

const AnimatedRoutes = () => {
    const location = useLocation()

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home />} />
                <Route path='/servicios/' element={<Servicios />} />
                <Route path='/servicios/:service' element={<ServicioRedirect />} />
                <Route path='/servicios/servicios-tecnologicos/:service' element={<ServicioTecnologicoRedirect />} />
                <Route path='/servicios/produccion-marketing/:service' element={<ProduccionMarketingRedirect />} />
                <Route path='/servicios/servicios-medicos/:service' element={<ServicioMedicoRedirect />} />
                {/* Educativo */}
                <Route path='/educativo/' element={<Educativo />} />
                <Route path='/educativo/oferta-educativa' element={<Cyberweek />} />
                <Route path='/educativo/lista-posgrados' element={<ListaPosgrados />} />
                <Route path='/educativo/lista-diplomaturas' element={<ListaDiplomaturas />} />
                <Route path='/educativo/lista-cursos/' element={<ListaCursos />} />
                <Route path='/educativo/lista-cursos/:curso' element={<CursoRedirect />} />
                <Route path='/educativo/lista-diplomaturas/:curso' element={<DiplomaturaRedirect />} />
                <Route path='/educativo/lista-posgrados/:curso' element={<PosgradoRedirect />} />
                <Route path='/educativo/categoria/:categoria' element={<FiltroCategoria />} />
                {/*<Route path='/educativo/pre-inscripcion/:curso' element={<PreInscripcion />} />*/}
                {/* Portal */}
                <Route path='/portal-de-noticias/:tituloNota' element={<DetalleNota />} />
                <Route path='/portal-de-noticias' element={<PortalV2 />} />
                <Route path='/portal-de-noticias/categoria/:categoria' element={<PortalCategoriaV2 />} />
                <Route path='/portal-de-noticias/busqueda/:busqueda' element={<PortalBusquedaV2 />} />
                {/* Editor */}
                <Route path='/portal-de-noticias/editor' element={<LoginPortalRedirect RutaPrivada={<Editor />} />} />
                <Route path='/portal-de-noticias/editor/previsualizar-nota/:tituloNota' element={<DetalleNotaPrev />} />
                <Route path='/portal-de-noticias/editor/previsualizar-nota' element={<LoginPortalRedirect RutaPrivada={<PrevisualizarNota />} />} />
                <Route path='/portal-de-noticias/editor/agregar-nota' element={<LoginPortalRedirect RutaPrivada={<AgregarNota />} />} />
                <Route path='/portal-de-noticias/editor/editar-nota' element={<LoginPortalRedirect RutaPrivada={<EditarNota />} />} />
                <Route path='/portal-de-noticias/editor/organizar-notas' element={<LoginPortalRedirect RutaPrivada={<OrganizarNotas />} />} />
                <Route path='/portal-de-noticias/editor/organizar-side' element={<LoginPortalRedirect RutaPrivada={<OrganizarSide />} />} />
                <Route path='/portal-de-noticias/editor/editar-reels' element={<LoginPortalRedirect RutaPrivada={<EditarReels />} />} />
                <Route path='/portal-de-noticias/editor/editar-stream' element={<LoginPortalRedirect RutaPrivada={<EditarStream />} />} />
                <Route path='/portal-de-noticias/login' element={<PortalLogin />} />
                {/* Formularios */}
                <Route path='/educativo/listado-de-formularios' element={<LoginEducativoRedirect RutaPrivada={<ListadoFormularios />} />} />
                <Route path='/educativo/login' element={<EducativoLogin />} />
                
                <Route path='/educativo/contacto/' element={<ContactoEducativo />} />
                <Route path='/servicios/contacto/' element={<ContactoServicios />} />
                <Route path='/la-fundacion/' element={<Fundacion />} />
                <Route path='/staff/' element={<Staff />} />
                <Route path='/terminos-y-condiciones/' element={<TerminosCondiciones />} />
                <Route path='/*' element={<PaginaError />} />
                {/* Test */}
                {/* <Route path='/test/1' element={<ServiciosTecnologicosV3 />} /> */}
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes